import React from 'react';

import { Layout } from 'antd';

import NavigationBar from '../../components/NavigationBar';

import './index.scss';

const { Content } = Layout;

class FindPetrol extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Layout className="find-petrol-wrapper">
        <NavigationBar />
        <Content>
          <div className="content">
            <h1>Privacy Policy</h1>
            <h2>Introduction</h2>
            <p>
              Welcome to FindPetrol. Access to and use of the Services is
              subject to the following. This Privacy Policy applies to the
              Services, and covers information collected on or through or in
              connection with your use of the Services. This Privacy Policy also
              applies to, without limitation, online and mobile Web sites,
              services and applications, as well as all components, features and
              downloads that are, or may be, available through the Services, or
              that interact with the Services and which are collectively
              included in the definition of &quot;Services&quot;. References to
              &quot;you&quot; in this Privacy Policy shall also include any
              other users of the Services.
            </p>
            <p>
              We reserve the right to modify the terms and conditions of this
              Privacy Policy or to change, modify or otherwise alter any feature
              of the Service, at any time in our sole discretion. The revised
              Privacy Policy will be made available for your review on the
              Services and/or the site. Please review the Privacy Policy
              periodically for any changes. Your continued use of the Services
              after the revised version is made available constitutes your
              agreement to the revision.
            </p>
            <p>
              BY USING OUR SERVICES AND/OR SITE, YOU CONSENT TO OUR PRIVACY
              POLICY AND TERMS OF USE AND OUR COLLECTION, USE AND SHARING OF
              YOUR INFORMATION AND DATA, AND OTHER ACTIVITIES, AS DESCRIBED
              BELOW.
            </p>
            <h2>1. INFORMATION COLLECTED</h2>
            <p>
              (a) WHEN INFORMATION IS COLLECTED. Your personally identifiable
              information and other information may be collected by us:
              <p>(i) when you create a Registered Account;</p>
              <p>
                (ii) when you create accounts linked to your Registered Account;
              </p>
              <p>
                (iii) when you register for promotions or other features of the
                Services;
              </p>
              <p>
                (iv) when you report any issues relating to the Services or your
                use of the Services;
              </p>
              <p>
                (v) when you provide reviews, ratings, feedback or post comments
                on the Services;
              </p>
              <p>
                (vi) when you engage in other transactions on the Services,
                including the purchase of products;
              </p>
              <p> (vii) when you answer a survey or participate in research;</p>
              <p>
                (viii) when you provide such information for any reason in the
                course of using the Services;
              </p>
              <p>
                (ix) in the course of investigating suspected violations of this
                Privacy Policy and the Terms of Use;
              </p>
              <p>
                and (x) automatically when you access the Services. (b)
                INFORMATION SUBMITTED BY YOU. On certain portions of the
                Services, you are requested to provide personally identifiable
                information which may include, without limitation:
              </p>
              <p>(i) your name;</p>
              <p>(ii) e-mail address;</p>
              <p>(iii) mailing address;</p>
              <p>(iv) cell phone numbers;</p>
              <p>(v) date of birth;</p>
              <p>(vii) screen name;</p>
              <p>(viii) credit card information;</p>
              <p>(ix) bank account information;</p>
              <p>
                and (x) information about your interests in and use of various
                products, programs, and services. Providing some of this
                information may be mandatory for certain functions, such as to
                establish a Registered Account, enjoy a promotion or trial, set
                Parental Controls, or enter a contest.
              </p>
              <p>
                (c) INFORMATION AUTOMATICALLY COLLECTED FROM YOU. We may also
                automatically collect certain other information when you access
                the Services, including, but not limited to:
              </p>
              <p>(i) the type of browser you are using;</p>
              <p>(ii) the type of operating system you are using;</p>
              <p>
                (iii) the IP address or other unique identifier such as a Device
                Identifier (defined in Paragraph 2(e) below) of any of your
                computer(s) or device(s) that are used to access the Services;
              </p>
              <p>
                (iv) the name of your Internet service provider or mobile
                carrier;
              </p>
              <p>(v) referring/exit pages;</p>
              <p>(vi) date/time stamp;</p>
              <p>(viii) web beacons;</p>
              <p>(ix) precise geolocation information;</p>
              <p>(x) your account preferences and account settings;</p>
              <p>
                (xi) information describing your use of the Services, including
                but not limited to content accessed by you, page/screen visits,
                length of time viewing a certain page or program and how the
                content is viewed;
              </p>
              <p>
                and (xii) information about your interests in and use of various
                products, programs, and services (collectively described as
                &quot;Usage Information,&quot; described in greater detail in
                Paragraph 2(e). below).
              </p>
              <p>
                When you use the Services, information may also be collected
                about one or more Devices (as defined in Paragraph 2(e) below)
                associated with you that you use to access the site and Services
                such as a computer, mobile phone, or tablet. This device
                information may also be collected based on information you
                provide to us, including when you log in to the site or Services
                from one or more Devices, register for an account, complete a
                web form, add or update your account information, or otherwise
                correspond with us regarding the Services.
              </p>
            </p>
            <h2>2. HOW THE INFORMATION IS USED</h2>
            <p>
              (a) TO ENABLE AND IMPROVE ACCESS TO THE SERVICES. We may use or
              share the information collected from you among each other, and
              with our vendors, affiliates, partners and distributors including,
              without limitation, to verify your eligibility to access the
              Services, to fulfill your requests for our products, programs, and
              services, to customize your use of the Services based on your
              viewing patterns and areas of interest, to verify the status of
              your Registered Account, for research and analytics, and to
              provide you with information regarding changes or updates to the
              Services, newsletters, and special offers and promotions.
            </p>
            <p>
              The information collected from you is used to provide an
              interactive experience, improve and develop user experience,
              facilitate your participation in online forums and communities
              and, from time to time, to offer you products, programs and
              services.
            </p>
            <p>
              If you choose to submit content for publication, we may publish
              your screen name and other information you have provided to us on
              the Services, on the Internet, or elsewhere.
            </p>
            <p>
              (b) TO ASSIST THIRD PARTIES ACTING ON OUR BEHALF. We may use third
              party vendors to perform certain services, such as hosting the
              site or application, fulfilling your orders of products and/or
              services, designing and/or operating the Services&apos features,
              tracking the Services&apos activities and analytics, and enabling
              us to send you special offers or performing other administrative
              services. These vendors may be provided with access to certain
              information, including, without limitation, user information,
              Device Identifiers and personally identifiable information, to
              carry out the services they are performing. Third party analytics
              service providers may be used for purposes such as to evaluate and
              provide us with information about the Services and viewing of our
              content. These vendors may set and access their own Tracking
              Technologies (defined in Paragraph 2(e) below) on your Device and
              they may otherwise collect or have access to information about
              you. Please recognize that we and/or our authorized third party
              vendors (such as mailing houses or customer service vendors) may
              store this information, and may share this information with other
              parties as necessary to process your transactions and provide you
              access to the Services. This information may also be used for
              internal review and analysis of the Services, the customer service
              process, and consumer comments.
            </p>
            <p>
              (c) TO COMMUNICATE WITH YOU. We sometimes use this information to
              communicate with you, such as to notify you when you have won one
              of our contests or when changes have been made to your subscriber
              agreement/account, to provide you with newsletters, promotional
              information about us and/or the Services, in connection with
              incentive programs, rewards and fan engagement activities, to
              respond to your inquiries about the offerings under the Services
              and provide customer service assistance, to offer you other
              products, programs or services that may be of interest to you, or
              to contact you about changes to the Services or other system
              requirements, your Registered Account, or any customer service
              requests submitted by you.
            </p>
            <p>
              (d) TO IMPROVE THE SERVICES. The information that we collect may
              sometimes be used to improve the design and content of the
              Services and to enable the personalization of your experience in
              using the Services. This information may also be used to analyze
              usage of the Services in the aggregate, to provide user ratings
              and other information to subscribers generally, to offer you
              products, programs, or services that you might enjoy, and to
              better assist with customer service. Such information may also be
              shared in the aggregate to enable partners, prospective partners,
              affiliates or third parties.
            </p>
            <p>
              (e) COOKIES AND USAGE INFORMATION COLLECTED AS YOU ACCESS AND USE
              THE SERVICES. In addition to any personally identifiable
              information or other information that you choose to submit to the
              Services, we and our authorized third-party service providers may
              use a variety of technologies that automatically or passively
              collect certain information whenever you visit or interact with
              the Services (&quot;Usage Information&quot;), which technologies
              may be downloaded to your Device (defined in Paragraph 2(e) below)
              and may set or modify settings on your Device. This Usage
              Information may include the browser that you are using, the URL
              that referred you to our Services, all the areas within our
              Services that you visit and your activities there, your Device
              location, your Device characteristics and certain Device data, and
              the time of day, among other information. Your precise geolocation
              may also be collected in real time from your Device if your
              consent is provided. Your Usage Information may also be used for a
              variety of purposes, including to assess, enhance or otherwise
              improve the Services or the activities in connection with it, for
              analytics or for marketing purposes. When you visit and/or use the
              Services, we may also use cookies or similar technology on your
              Devices to collect information about you or from activity on
              Devices associated with you as soon as you visit the Services. By
              using the Services, you permit your information from activity on
              Devices associated with you to be collected and used in accordance
              with this Privacy Policy. In addition, your IP address,
              International Mobile Equipment Identity (IMEI) number or other
              identifier (&quot;Device Identifier&quot;) for your computer or
              other permitted device used to access the Services (any, a
              &quot;Device&quot;) may be collected. A Device Identifier is an
              identifier that is automatically assigned to your Device used to
              access the Services, and your Device is identified by its Device
              Identifier. We may access and use Device Identifiers that have
              already been associated with your Device.
            </p>
            <p>
              We may use various new and hereafter developed technologies to
              collect Device Identifiers, Usage Information and Demographic
              Information about you (&quot;Tracking Technologies&quot;). A few
              of the methods that may be used to collect Usage Information
              include, without limitation, the following (and subsequent
              technology and methods hereafter developed):
            </p>
            <p>
              COOKIES. A browser cookie is a data file placed on a Device when
              it is used to visit the Services. Cookies may be used for many
              purposes, including, without limitation, remembering you and your
              preferences and tracking your visits to and activities in
              connection with the Services. The Services also may use locally
              stored objects to provide certain content, such as video on
              demand, video clips, or animation. Most cookies may be disabled or
              removed or blocked using tools and settings on commercial
              browsers. However, some cookies, such as Adobe Flash cookies or
              HTML5 cookies, may not be disabled through your browser. Please be
              aware that if you disable or remove cookies some parts of our
              Services may not work and that when you revisit our Services, your
              ability to limit cookies is subject to your browser settings and
              limitations.
            </p>
            <p>
              WEB BEACONS. Web beacons are small strings of code that provide a
              method for delivering a graphic image on a web page for the
              purpose of transferring data, such as: the IP address of the
              computer that downloaded the page on which the web beacon appears,
              the URL (Uniform Resource Locator) of the page on which the web
              beacon appears, the time the page containing the web beacon was
              viewed, the types of browser that fetched the web beacon, the
              identification number of any cookie on the computer previously
              placed by that server, or whether an email has been opened. Web
              beacons may be invisible to you, but any electronic image or other
              web programming code inserted into a web page or e-mail can act as
              a web beacon. The Services may use web beacons for a number of
              purposes, including, without limitation, to count visitors to the
              Services, to monitor how users navigate the Services, to count how
              many e-mails that were sent were actually opened or to count how
              many particular articles or links were actually viewed.
            </p>
            <p>
              (f) THIRD PARTY AD SERVERS. Third party ad servers may present or
              serve advertisements, provide us with data collection, reporting,
              ad response measurement, and site analytics, and assist with
              delivery of relevant marketing messages and advertisements. These
              third parties may view, edit, or set their own cookies. They may
              also collect information concerning your online activities over
              time and across different websites. The information they may
              collect includes the other sites you have visited or applications
              that you have downloaded to your mobile device, and other
              information about you or your device in order to help analyze and
              serve anonymous targeted advertising on the Services and
              elsewhere.
            </p>
            <p>
              (g) CONDUCTING INVESTIGATIONS. Your personally identifiable
              information and other information may be used or disclosed to
              third parties in connection with fraud-scoring, detection, and
              prevention activities or where it is believed to be necessary to
              investigate, prevent, or take action regarding illegal activities,
              suspected fraud, situations involving potential threats to the
              physical safety of any person, violations of the Terms of Use, to
              protect our rights and the rights of others, and as otherwise
              required by law.
            </p>
            <p>
              (h) ADDITIONAL USES. On occasion, your information may be combined
              with additional records (such as information from affiliated
              companies, partners, Operators, or outside vendors, and publicly
              available information, including on social media sites). The
              combined information may be used for purposes such as to market
              products, features, or services that may be of interest to you,
              for research and analytics purposes, and to assist in providing,
              personalizing and improving the Services and other offerings.
            </p>
            <h2>3. SHARING YOUR INFORMATION WITH THIRD PARTIES</h2>
            <p>
              Information may be shared as set forth more particularly in
              Paragraph 2 of this Privacy Policy. In addition, there may be
              times when your information, including your personally
              identifiable information, may be shared as follows:
            </p>
            <p>
              (a) ADMINISTRATIVE AND LEGAL REASONS. Your personally identifiable
              information may be disclosed in response to legal process and/or
              government requests, for example, in response to a court order or
              a subpoena, or an official request from a government and/or law
              enforcement authority.
            </p>
            <p>
              (b) SALE OF ASSETS OR CHANGE OF CONTROL. In the event of a sale of
              assets or change in ownership, such information may be transferred
              to a successor, assign or any person or entity that acquires
              ownership or control of the business or the Services via any
              means.
            </p>
            <p>
              (c) FOR AFFILIATE USE. As you know, we are a WarnerMedia company,
              and as such, have affiliate companies. We may share your
              personally identifiable information with our affiliates to
              identify products and services that may be of interest to you, for
              research, analysis and to assist in the improvement of our
              Services or those of our affiliates, and in connection with
              promotions conducted by us, by our affiliates, or jointly.
            </p>
            <p>
              (d) CO-BRANDED OPPORTUNITIES: We sometimes may offer content
              (e.g., contests, sweepstakes, or promotions) that is sponsored by
              or co-branded with identified third parties. By virtue of these
              relationships, the third parties may obtain personally
              identifiable information that visitors voluntarily submit as a
              result of participation in such content or activities. We have no
              control over these parties&apos use of this information. You will
              be notified at the time your personally identifiable information
              is requested whether such information will be shared with these
              parties.
            </p>
          </div>
        </Content>
      </Layout>
    );
  }
}

export default FindPetrol;
