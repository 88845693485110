import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// Components
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import FindPetrol from './pages/FindPetrol';

const history = createBrowserHistory();

const routes = [
  {
    path: '/blog/:id',
    component: BlogDetail,
  },
  {
    path: '/blog',
    component: Blog,
  },
  {
    path: '/findpetrol',
    component: FindPetrol,
  },
  {
    path: '/',
    component: Home,
  },
];

const Router = props => (
  <BrowserRouter>
    <Switch>
      {routes.map(route => (
        <Route
          key={route.path}
          history={history}
          component={route.component}
          path={route.path}
        />
      ))}
    </Switch>
    {props.children}
  </BrowserRouter>
);

export default Router;
