import React from 'react';
import { Layout, Icon, Row, Col } from 'antd';
import DeveloperImage from '../../assets/developer.svg';
import NavigationBar from '../../components/NavigationBar';
import './index.scss';

const { Content } = Layout;
const links = {
  github: 'https://github.com/qihaomah',
  facebook: 'https://www.facebook.com/mah.qi.hao',
  instagram: 'https://www.instagram.com/qihao1996/',
  youtube: 'https://www.youtube.com/user/qihaomah',
  linkedin: 'https://www.linkedin.com/in/qihaomah/',
};
const roles = ['Developer', 'Software Engineer', 'Gamer', 'Programmer'];
const allRoles = roles.map(role => {
  const margin = 20 + Math.random() * 20;
  return (
    <span style={{ display: 'inline-block', margin: margin }} key={role}>
      {role}
    </span>
  );
});
const fiveTimes = new Array(10);

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
    this.personalInfoRef = React.createRef();
  }

  componentDidMount() {
    setTimeout(() => {
      this.personalInfoRef.current.classList.toggle('active');
    }, 100);
  }

  handleSocialMediaClick = socialMedia => {
    window.open(links[socialMedia], '_blank');
  };

  render() {
    return (
      <Layout className="home-wrapper">
        <NavigationBar />
        <Content className="content">
          <Row className="header-row">
            <Col xs={24} md={12} className="personal-info">
              <div ref={this.personalInfoRef}>
                <h3 className="name">Qi Hao</h3>
                <h4 className="job-title">Software Engineer</h4>
                <p className="description">
                  A developer from <b className="country">Malaysia</b>.
                  Interested in competitive programming, any development work.
                  Currently working at
                  <b className="company"> Shopee</b>
                </p>
                <h4 className="welcome">Welcome to my home!</h4>
                <div className="social-media-icons-container">
                  <Icon
                    className="social-media-icon"
                    type="facebook"
                    title="facebook"
                    onClick={this.handleSocialMediaClick.bind(this, 'facebook')}
                  />
                  <Icon
                    className="social-media-icon"
                    type="youtube"
                    title="youtube"
                    onClick={this.handleSocialMediaClick.bind(this, 'youtube')}
                  />
                  <Icon
                    className="social-media-icon"
                    type="instagram"
                    title="instagram"
                    onClick={this.handleSocialMediaClick.bind(
                      this,
                      'instagram'
                    )}
                  />
                  <Icon
                    className="social-media-icon"
                    type="github"
                    title="github"
                    onClick={this.handleSocialMediaClick.bind(this, 'github')}
                  />
                  <Icon
                    className="social-media-icon"
                    type="linkedin"
                    title="linkedin"
                    onClick={this.handleSocialMediaClick.bind(this, 'linkedin')}
                  />
                </div>
              </div>
            </Col>
            <Col xs={24} md={12} className="words-stack">
              {fiveTimes.fill(1).map(() => {
                console.log(1);
                return allRoles;
              })}
            </Col>
          </Row>
          <div className="main-introduction">
            <h2>Background</h2>
            <p className="main-background-info">
              Born in December 1996. Graduated in July 2018.
              <br />
              <br />
              The blog will be updated constantly, mainly about the updated
              things that I have learned.
              <br />
              <br />
              Stay tuned!
            </p>
            <img
              alt="Developer"
              src={DeveloperImage}
              className="developer-image"
            />
          </div>
          <Row gutter={16} className="bottom-container">
            <Col xs={24} md={11} className="skills-container">
              <div>
                <h3>Programming Languages</h3>
                <ul>
                  <li>Javascript</li>
                  <li>Swift</li>
                </ul>
              </div>
              <div>
                <h3>Databases</h3>
                <ul>
                  <li>MongoDB / Mongoose</li>
                  <li>MySQL</li>
                  <li>SQLplus</li>
                  <li>Neo4j</li>
                  <li>Postgres</li>
                  <li>Redis</li>
                </ul>
              </div>
            </Col>
            <Col xs={24} md={11} className="skills-container">
              <div>
                <h3>Frameworks</h3>
                <ul>
                  <li>React</li>
                  <li>React Native</li>
                  <li>Express</li>
                </ul>
              </div>
              <div>
                <h3>Additional</h3>
                <ul>
                  <li>AWS cloud services</li>
                  <li>Caching</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

export default Home;
