import React from 'react';
import Markdown from 'react-markdown';
import { Layout } from 'antd';
import axios from 'axios';
import NavigationBar from '../../components/NavigationBar';
import blogs from '../../components/BlogList/blogs.json';
import './index.scss';

const { Content } = Layout;
class BlogDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const blog = blogs[id - 1];
    const markdown = require(`../../components/BlogList/${blog.file}`);
    const result = (await axios.get(markdown)).data;
    this.setState({ result });
  }

  render() {
    const { result } = this.state;

    return (
      <Layout className="blog-detail-page">
        <NavigationBar />
        <Content>
          <div className="content">
            {result && <Markdown source={result} />}
          </div>
        </Content>
      </Layout>
    );
  }
}

export default BlogDetail;
