import React from 'react';
import { Layout } from 'antd';
import NavigationBar from '../../components/NavigationBar';
import BlogList from '../../components/BlogList';
import './index.scss';

const { Content } = Layout;

class Blog extends React.Component {
  render() {
    return (
      <Layout className="blog-wrapper">
        <NavigationBar />
        <Content className="content">
          <div className="blog-list">
            <h1>Blog</h1>
            <BlogList />
          </div>
        </Content>
      </Layout>
    );
  }
}

export default Blog;
