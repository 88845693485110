import React from 'react';
import { Layout, Button } from 'antd';
import { Link } from 'react-router-dom';
import './index.scss';

const { Header } = Layout;

const NavigationBar = () => (
  <Header className="header">
    <div className="logo">Qi Hao</div>
    <div className="navigation-buttons">
      <Button type="link">
        <Link to="/">Home</Link>
      </Button>
      <Button type="link">
        <Link to="/blog">Blog</Link>
      </Button>
    </div>
  </Header>
);

export default NavigationBar;
