import React from 'react';
import BlogListCell from './BlogListCell';
import blogs from './blogs.json';

class BlogList extends React.Component {
  render() {
    return blogs.map(blog => <BlogListCell blog={blog} />);
  }
}

export default BlogList;
