import React from 'react';
import { withRouter } from 'react-router-dom';
import './index.scss';

class BlogListCell extends React.Component {
  goToDetail = () => {
    const { blog } = this.props;
    this.props.history.push(`/blog/${blog.id}`);
  };

  render() {
    const { blog } = this.props;

    return (
      <div className="blog-list-container" onClick={this.goToDetail.bind(this)}>
        <h2 className="title">{blog.title}</h2>
        <p className="description">{blog.description}</p>
      </div>
    );
  }
}

export default withRouter(BlogListCell);
