import React from 'react';
import { render } from 'react-dom';
import Router from './router';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import './index.scss';

const App = () => <Router />;

serviceWorker.register();
const target = document.querySelector('#root');
render(<App />, target);
